import DBConnector from '../Data/DBConnector';
import ProductCategory from './ProductCategory';
import ProductIcon from './ProductIcon';

export default class ProductNode {
    public category: ProductCategory | null;
    public icon: ProductIcon | null;

    constructor(
        public id: number,
        public name: string,
        categoryId: number,
        iconId: number
    ) {
        this.category = DBConnector.getProductCategory(categoryId);
        this.icon = DBConnector.getProductIcon(iconId);
    }
}
