import Product from '../DataStructures/Product';
import ProductCategory from '../DataStructures/ProductCategory';
import ProductIcon from '../DataStructures/ProductIcon';
import ProductNode from '../DataStructures/ProductNode';
import ProductSku from '../DataStructures/ProductSku';
import productCategories from './categories.json';
import productIcons from './icons.json';
import productNodes from './nodes.json';
import products from './products.json';
import productSkus from './skus.json';

// TODO: create DB and use sql queries for data
export default class DBConnector {
    static getProductNode(id: number): ProductNode | null {
        if (id === null) {
            return null;
        }

        const productNode = productNodes.find((item) => item.id === id);

        if (!productNode) {
            return null;
        }

        return new ProductNode(
            productNode.id,
            productNode.name,
            productNode.categoryId,
            productNode.iconId
        );
    }

    static getProduct(id: number | null) {
        if (id === null) {
            return null;
        }

        const product = products.find((item) => item.id === id);
        if (!product) {
            return null;
        }

        return new Product(product.id, product.name, product.nodeId);
    }

    static getProductCategory(id: number) {
        if (id === null) {
            return null;
        }
        const category = productCategories.find((item) => item.id === id);

        if (!category) {
            return null;
        }

        return new ProductCategory(category.id, category.name);
    }

    static getProductIcon(id: number) {
        if (id === null) {
            return null;
        }
        const icon = productIcons.find((item) => id === item.id);

        if (!icon) {
            return null;
        }

        return new ProductIcon(icon.id, icon.path);
    }

    static getProductSku(id: number) {
        if (id === null) {
            return null;
        }
        const productSku = productSkus.find((item) => item.id === id);

        if (!productSku || productSku === null) {
            return null;
        }

        return new ProductSku(
            productSku.id,
            productSku.name,
            productSku.articleCode,
            productSku.productId
        );
    }

    static getProductNodesForProductCategory(categoryId: number) {
        const filteredProductNodes = productNodes.filter(
            (item) => item.categoryId === categoryId
        );
        return filteredProductNodes.map((productNode) => ({
            id: productNode.id,
            name: productNode.name,
        }));
    }

    static getProductNodesForProductCategoryWithIconsAndSKUs(
        categoryId: number
    ) {
        const filteredProductNodes = productNodes.filter(
            (item) => item.categoryId === categoryId
        );
        return filteredProductNodes.map((productNode) => ({
            id: productNode.id,
            name: productNode.name,
            icon: DBConnector.getProductIcon(productNode.iconId),
            skus: DBConnector.getProductsForProductNode(productNode.id)
                .map((product) =>
                    DBConnector.getProductSkusForProductWithArticleCode(
                        product.id
                    )
                )
                .flat(),
        }));
    }

    static getProductsForProductNode(nodeId?: number | null) {
        if (typeof nodeId !== 'number') {
            return [];
        }
        const filteredProducts = products.filter(
            (item) => item.nodeId === nodeId
        );
        return filteredProducts.map((product) => ({
            id: product.id,
            name: product.name,
        }));
    }

    static getProductSkusForProduct(productId?: number | null) {
        if (typeof productId !== 'number') {
            return [];
        }
        const filteredProductSkus = productSkus.filter(
            (item) => item.productId === productId
        );
        return filteredProductSkus.map((productSku) => ({
            id: productSku.id,
            name: productSku.name,
        }));
    }

    static getProductSkusForProductWithArticleCode(productId: number) {
        const filteredProductSkus = productSkus.filter(
            (item) => item.productId === productId
        );
        return filteredProductSkus.map((productSku) => ({
            id: productSku.id,
            name: productSku.name,
            articleCode: productSku.articleCode,
        }));
    }

    static getProductCategories() {
        return productCategories.map(
            ({ id, name }) => new ProductCategory(id, name)
        );
    }
}
