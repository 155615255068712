import { Module } from 'vuex';

export type PreviewStoreState = {
    /**
     * Is the current preview image outdated? This value is set to true by all are store modules
     * that modify the state of the editor.
     */
    outdated: boolean;
    /**
     * How many times has the preview been updated already? (Essentially just a counter kept track
     * of to help with debugging en performance testing).
     */
    updates: number;
    /**
     * Current (possibly outdated) preview image as PNG in base64 string format.
     */
    previewImage: string;
    /**
     * Counter/hash that keeps track of how many times the current state has been marked as
     * outdated. This is needed because generating a preview image happens asynchronously so the
     * renderer needs to know if the preview image it just finished rendering has not been
     * invalidated in the mean time.
     */
    version: number;
};

export default <Module<PreviewStoreState, {}>>{
    namespaced: true,
    state: {
        outdated: true,
        updates: 0,
        previewImage: '',
        version: 0,
    },
    getters: {
        outdated: (state) => state.outdated,
        updates: (state) => state.updates,
        previewImage: (state) => state.previewImage,
        version: (state) => state.version,
    },
    mutations: {
        /**
         * Updates the preview image and sets outdated to false if the new image was outdated
         * during its creation.
         *
         * @param image - New preview image.
         * @param version - What was the version number when this image was rendered?
         */
        updatePreviewImage(state, arg: { image: string; version: number }) {
            state.previewImage = arg.image;
            state.updates += 1;
            if (state.version === arg.version) {
                state.outdated = false;
            }
        },
        /**
         * Marks the current preview image as being outdated so watchers can know to generate a
         * new one.
         */
        markOutdated(state) {
            state.outdated = true;
            state.version += 1;
        },
    },
    actions: {
        markOutdated(context) {
            context.commit('markOutdated');
        },
    },
};
