import { Module } from 'vuex';

import Configuration from '@/helpers/Configuration';
import { exportV1, loadConfig } from '@/helpers/save';

export type HistoryStoreState = {
    entries: any[];
    index: number;
}

function getDefaultState(): HistoryStoreState {
    return {
        entries: [localStorage.getItem('config')],
        index: 0,
    };
}

export default <Module<HistoryStoreState, {}>>{
    namespaced: true,
    state: getDefaultState(),
    getters: {},
    actions: {
        undo({ state, dispatch }) {
            if (state.index < state.entries?.length - 1) {
                state.index++;
                if (state.entries[state.index]) {
                    loadConfig(JSON.parse(state.entries[state.index]));
                }
            }
            dispatch('saveState');
        },
        redo({ state, dispatch }) {
            if (state.index > 0) {
                state.index--;
                if (state.entries[state.index]) {
                    loadConfig(JSON.parse(state.entries[state.index]));
                }
            }
            dispatch('saveState');
        },
        reset({ state, dispatch }) {
            Object.assign(state, getDefaultState());
            dispatch('saveState');
        },
        add({ state, dispatch }) {
            // Save config so that is can be reverted
            // Start from current point in history
            const config = JSON.stringify(exportV1());
            if (config === state.entries[state.index]) {
                return;
            }
            state.entries = state.entries.slice(state.index);
            state.index = 0;

            state.entries.unshift(config);
            if (state.entries.length > Configuration.HISTORY_LENGTH) {
                state.entries.length = Configuration.HISTORY_LENGTH;
            }

            dispatch('saveState');
            dispatch('preview/markOutdated', {}, { root: true });
        },
        /**
         * Saves the current state to local storage.
         */
        saveState({ state }) {
            if (state.entries.length === 0) {
                localStorage.removeItem('config');
                return;
            }
            const config = state.entries[state.index];
            if (config || config !== 'null') {
                localStorage.setItem('config', config);
            }
        },
    },
};
