import { Tooltip } from 'bootstrap';

// See https://stackoverflow.com/questions/37078423/how-can-add-bootstrap-tooltip-inside-vue-js

// NOTE: Make sure each tooltip has a unique `binding.value`

const tooltips = [];

const tooltip = (el, binding) => {
    if (tooltips[binding.value]) {
        tooltips[binding.value].dispose();
    }

    tooltips[binding.value] = new Tooltip(el, {
        title: binding.value,
        placement: binding.arg,
        trigger: 'hover',
        delay: 500,
        container: 'body',
    });
};

export default tooltip;
