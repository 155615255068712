import { v4 as uuidv4 } from 'uuid';
import { Module } from 'vuex';

export type ToastData = {
    title: string;
    body: string;
    type: 'warning' | 'white';
};

export type Toasts = {
    toasts: { [key: Uuid]: ToastData };
    retrigger: { [key: Uuid]: number };
};

export default <Module<Toasts, {}>>{
    namespaced: true,
    state: {
        toasts: {},
        retrigger: {},
    },
    getters: {
        entries(state) {
            return state.toasts;
        },
        retrigger(state) {
            return state.retrigger;
        },
    },
    actions: {
        addToast({ state }, data) {
            // CHECK: Is there already a toast with this exact message still visible?
            const oldToast = Object.entries(state.toasts).filter(
                ([_, t]) =>
                    t.title === data.title &&
                    t.body === data.body &&
                    t.type === data.type
            );

            // If so, retrigger that toast instead of spamming a new one with the same message.
            if (oldToast.length > 0) {
                const [[id], _] = oldToast;
                state.retrigger[id] += 1;
            }

            // Make a new toast.
            else {
                const id = uuidv4();
                state.toasts[id] = data;
                state.retrigger[id] = 1;
            }
        },
        removeToast({ state }, id) {
            delete state.toasts[id];
            delete state.retrigger[id];
        },
    },
};
