import popover from './popover';
import tooltip from './tooltip';

// See https://stackoverflow.com/questions/70690728/how-to-register-a-global-directive-in-vue-for-entire-app

export default {
    install(Vue) {
        Vue.directive('tooltip', tooltip);
        Vue.directive('popover', popover);
    },
};
