import { EventMode } from '@pixi/events';
import { Container, IPointData } from 'pixi.js';

import Configuration from '@/helpers/Configuration';

import Connection from './Connection';

export default class GridMenu extends Container {
    globalLocation!: IPointData;
    eventMode: EventMode = 'static';

    constructor(input: {
        name: string;
        x: number;
        y: number;
        connections: Connection[] | null | undefined;
    }) {
        super();
        Object.assign(this, input);
        this.eventMode = 'static';
        this.location = {
            x: this.x * Configuration.TILE_SIZE,
            y: this.y * Configuration.TILE_SIZE,
        };
    }

    public set location({ x, y }: Coordinates) {
        this.position.set(x, y);
        this.updatePosition();
    }

    public get location(): Coordinates {
        const { x, y } = this.position;
        return { x, y };
    }

    private updatePosition() {
        this.globalLocation = this.toGlobal({
            x: Configuration.TILE_SIZE / 2,
            y: Configuration.TILE_SIZE / 2,
        });
    }
}
