import { Module } from 'vuex';

import { Tools } from '@/helpers/enums';

export type ToolsStoreState = {
    current: Tools | null;
};

export default <Module<ToolsStoreState, {}>>{
    namespaced: true,
    state: {
        current: null,
    },
    getters: {},
    actions: {
        update({ state }, toolName: Tools) {
            if (!Object.values(Tools).includes(toolName)) {
                throw new Error(
                    `Could not change ${state.current} to ${toolName}. Not a valid tool`
                );
            }
            state.current = toolName;
        },
        // For renderer
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        action() {},
    },
};
