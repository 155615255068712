export const DEFAULT_TOOL = 'SELECT';

export enum Tools {
    SELECT = 'select',
    MOVE = 'move',
}

export const TOOLBAR_ACTIONS = {
    ZOOM_IN: 'zoom-in',
    ZOOM_OUT: 'zoom-out',
    UNDO: 'undo',
    REDO: 'redo',
};

export enum LineTypes {
    NORMAL = 'normal',
    DASHED = 'dashed',
}

export enum ConnectionColors {
    BLUE = '#01a8f4',
    ORANGE = '#FF8A65',
    GREEN = '#A0D87B',
    BLACK = '#11263B',
}

export const UI_COLORS = {
    SELECT: {
        MAIN: '#FFDACE',
        ACCENT: ConnectionColors.ORANGE,
    },
    DESELECT: {
        MAIN: '#E1F5FE',
        ACCENT: ConnectionColors.BLUE,
    },
    HOVER: {
        MAIN: '#e3f3d8',
        ACCENT: ConnectionColors.GREEN,
    },
};
