import { Container } from 'pixi.js';

import Configuration from '@/helpers/Configuration';

export default class Note extends Container {
    globalLocation = null;
    constructor(id, { x, y }) {
        super();
        this.id = id;
        this.text = '';
        this.title = 'Note';
        this.location = { x, y };
        this.eventMode = 'static';
        this.dragging = false;
    }

    set dragging(st) {
        if (st) {
            this._dragging = true;
            this.on('globalmousemove', (e) => {
                this.location = e.getLocalPosition(this.parent);
            });
            return;
        }
        this.removeListener('globalmousemove');
        this._dragging = false;
    }

    get dragging() {
        return this._dragging;
    }

    set location({ x, y }) {
        this.position.set(x, y);
        this.updatePosition();
    }

    get location() {
        const { x, y } = this.position;
        return { x, y };
    }

    get lines() {
        return this.text.split('\n').length;
    }

    updatePosition() {
        this.globalLocation = this.toGlobal({
            x: Configuration.TILE_SIZE / 2,
            y: Configuration.TILE_SIZE / 2,
        });
    }
}
