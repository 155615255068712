import { Popover } from 'bootstrap';

// See https://stackoverflow.com/questions/37078423/how-can-add-bootstrap-tooltip-inside-vue-js

const popovers = [];

const popover = (el, binding) => {
    if (popovers[binding.value[0]]) {
        popovers[binding.value[0]].dispose();
    }

    popovers[binding.value[0]] = new Popover(el, {
        title: binding.value[0],
        content: binding.value[1],
        placement: binding.arg,
        trigger: 'hover',
        container: 'body',
        html: true,
    });
};

export default popover;
