import { Module } from 'vuex';

import Note from '@/components/Schematic/iso/Note';

export type NotesStoreState = {
    notes: Note[];
    currentlyEditingId: any;
};

export default <Module<NotesStoreState, {}>>{
    namespaced: true,
    state: {
        notes: [],
        currentlyEditingId: null,
    },
    getters: {
        note(state): (id: Uuid) => Note | null {
            return (noteId: Uuid) =>
                state.notes.find((note) => note.id === noteId) || null;
        },
        entries: (state) => () => state.notes,
        ids: (state) => () => state.notes.map((note) => note.id),
        currentlyEditingId: (state) => () => state.currentlyEditingId,
    },
    actions: {
        add({ state, dispatch }, { id, location, doNotStore }) {
            if (!id) {
                return;
            }
            const newNote = new Note(id, location);
            state.notes.push(newNote);
            if (!doNotStore) {
                state.currentlyEditingId = id;
                dispatch('history/add', {}, { root: true });
            }
        },
        remove({ state, dispatch }, { id, doNotStore }) {
            if (!id) {
                return;
            }
            state.notes = state.notes.filter((note) => id !== note.id);
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        reset({ getters, dispatch }) {
            getters.entries().forEach(({ id }) => {
                dispatch('remove', { id, doNotStore: true });
            });
        },
        changeText({ getters, dispatch }, { id, text, doNotStore }) {
            getters.note(id).text = text;
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        changeTitle({ getters, dispatch }, { id, title, doNotStore }) {
            getters.note(id).title = title;
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        setCurrentlyEditing({ state }, { id }) {
            state.currentlyEditingId = id;
        },
    },
};
