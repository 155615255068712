import DBConnector from '../Data/DBConnector';
import Product from './Product';

export default class ProductSku {
    product: Product | null;

    constructor(
        public id: number,
        public name: string,
        public articleCode: string | null,
        productId: number
    ) {
        this.product = DBConnector.getProduct(productId);
    }
}
