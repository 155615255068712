// import ProductCategory from "./ProductCategory";

import DBConnector from '../Data/DBConnector';
import ProductNode from './ProductNode';

export default class Product {
    node: ProductNode | null;

    constructor(public id: number, public name: string, nodeId: number) {
        this.node = DBConnector.getProductNode(nodeId);
    }
}
