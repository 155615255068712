import { createStore } from 'vuex';

import GridMenu from '@/components/Schematic/iso/GridMenu';

import ConnectionsStore from './connections';
import HistoryStore from './history';
import NodesStore from './nodes';
import NotesStore from './notes';
import PreviewStore from './preview';
import ToastsStore from './toasts';
import ToolStore from './tools';
import UIStateStore from './ui_state';

export type StoreState = {
    openMenu: GridMenu | null;
    projectName: string;
    projectNotes: string;
};

export default createStore<StoreState>({
    modules: {
        preview: PreviewStore,
        nodes: NodesStore,
        connections: ConnectionsStore,
        tools: ToolStore,
        history: HistoryStore,
        ui_state: UIStateStore,
        notes: NotesStore,
        toasts: ToastsStore,
    },
    state: {
        openMenu: null,
        projectName: '',
        projectNotes: '',
    },
    actions: {
        updateProjectName({ state, dispatch }, { projectName, doNotStore }) {
            state.projectName = projectName;
            if (!doNotStore) {
                dispatch('history/add');
            }
        },
        updateProjectNotes({ state, dispatch }, { projectNotes, doNotStore }) {
            state.projectNotes = projectNotes;
            if (!doNotStore) {
                dispatch('history/add');
            }
        },
        reset({ state, dispatch }) {
            // No need to reset connections. Nodes reset connections themselves
            dispatch('nodes/reset', { doNotStore: true });
            dispatch('notes/reset');
            // do not reset history when we do not want to store the reset
            dispatch('history/reset');
            state.projectName = '';
            state.projectNotes = '';
        },
        openGridMenu({ state }, { name, ...data }) {
            state.openMenu = new GridMenu({ name, ...data });
        },
        closeGridMenu({ state }) {
            state.openMenu = null;
        },
    },
});
